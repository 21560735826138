.wrapper {
	padding: 200px 0 100px;
	position: relative;
	overflow: hidden;
}

.backgroundContainer {
	position: absolute;
	top: 0;
	right: 0;
	width: 30%;
	height: 100%;
	min-width: 450px;
}

.backgroundContainer img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	position: relative;
}

.border {
	height: 200%;
	width: 60px;
	background-color: var(--red);
	position: absolute;
	top: -50%;
	left: 40%;
	rotate: 35deg;
}

.border::after {
	content: "";
	position: absolute;
	top: 0;
	left: -800%;
	background-color: var(--white);
	width: 800%;
	height: 170%;
}

.container {
	position: relative;
	z-index: 1;
}

.heading {
	width: 70%;
}

.description {
	display: inline-block;
	width: 75%;
	min-width: 300px;
	max-width: 950px;
	margin-top: 80px;
	border-left: 8px solid var(--red);
	padding-left: 20px;
	display: flex;
	align-items: center;
	gap: 20px;
}

.description img {
	height: auto;
	max-width: 300px;
	object-fit: contain;
	max-height: 300px;
}

.badgeImage {
	width: 100%;
}

@media screen and (max-width: 1400px) {
	.description {
		max-width: 700px;
	}

	.border {
		left: 55%;
	}
}

@media screen and (max-width: 1024px) {
	.wrapper {
		padding: 40px 5% 40px;
	}

	.heading {
		width: 100%;
		text-align: start;
	}

	.border {
		height: 200%;
		width: 60px;
		background-color: var(--red);
		position: absolute;
		top: -50%;
		left: 60%;
		rotate: 35deg;
	}

	.description {
		flex-direction: row-reverse;
		align-items: flex-start;
		border-left: none;
		padding-left: 0;
		margin-top: 40px;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		padding: 40px 20px 40px;
	}
	.heading {
		text-align: center;
	}

	.description {
		margin-top: 20px;
		margin-bottom: 40px;
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.badgeImage {
		width: 100%;
	}

	.backgroundContainer {
		position: relative;
		min-width: unset;
		width: 100%;
	}

	.border,
	.border::after {
		display: none;
	}
}
