.wrapper {
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 95vh;
	width: 100%;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 1);
	opacity: 0.4;
}

.container {
	position: relative;
	z-index: 1;
	padding: 200px 20px;
}

.container strong {
	color: var(--red);
}

.content {
	width: 55%;
}

.description {
	display: block;
	border-left: 10px solid var(--red);
	padding-left: 20px;
	margin: 40px 0;
}

.btnContainer {
	display: flex;
	gap: 20px;
}

.video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	z-index: 0;
}

@media screen and (max-width: 1024px) {
	.wrapper {
		min-height: 80vh;
	}
	.content {
		width: 70%;
	}
}

@media screen and (max-width: 768px) {
	.wrapper {
		min-height: 100vh;
	}

	.container {
		padding: 160px 20px 100px;
	}

	.content {
		width: 100%;
	}

	.btnContainer {
		flex-direction: column;
	}
}
